import * as Ably from 'ably/promises';
import Cookies from 'js-cookie';

const initAblyClient = async ({
  userId,
  appId,
}: {
  userId: string;
  appId: string;
}): Promise<Ably.Realtime> => {
  const authCookie = `ablyAuthToken_${appId}_${userId}`;
  const storedToken = Cookies.get(authCookie);
  const ablyClient = new Ably.Realtime({
    authUrl: 'https://events.flipdish.com/api/eventAuth',
    authHeaders: { appId, userId },
    clientId: userId,
    ...(storedToken && { token: storedToken }),
  });

  return await new Promise((resolve) => {
    ablyClient.connection.on('connected', () => {
      // @ts-expect-error this is just TS being TS. It exists
      const authToken = ablyClient.auth.tokenDetails.token;
      // @ts-expect-error this is just TS being TS. It exists
      const authTokenExpire = ablyClient.auth.tokenDetails.expires;
      Cookies.set(authCookie, authToken, {
        expires: new Date(authTokenExpire),
        path: '/',
        secure: true,
      });
      console.log('Connected to Ably!');
      resolve(ablyClient);
    });
  });
};

export { initAblyClient };
